/*------ Normalise CSS ------*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:"";content:none}table{border-collapse:collapse;border-spacing:0}input::placeholder,textarea::placeholder{color:var(--placeholder)!important}input::-moz-placeholder,textarea::-moz-placeholder{color:var(--placeholder)!important;opacity:1}input::-webkit-input-placeholder,textarea::-webkit-input-placeholder{color:var(--placeholder)!important;opacity:1}input:focus{outline:0!important}input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}button{background:0 0;border:0;padding:0;line-height:0;font-family:inherit;cursor:pointer}button:focus,div:focus,textarea:focus{outline:0!important}select:focus{outline:0}a,a:hover{text-decoration:none;color:inherit}

/*------ Normalise End ------*/

/*------ Fonts ------*/

@font-face {
    font-family: "HelveticaNeue";
    src: url("../fonts/HelveticaNeueThin.woff2") format("woff2"),
        url("../fonts/HelveticaNeueThin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("../fonts/HelveticaNeueLight.woff2") format("woff2"),
        url("../fonts/HelveticaNeueLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("../fonts/HelveticaNeueRegular.woff2") format("woff2"),
        url("../fonts/HelveticaNeueRegular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("../fonts/HelveticaNeueMedium.woff2") format("woff2"),
        url("../fonts/HelveticaNeueMedium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("../fonts/HelveticaNeueBold.woff2") format("woff2"),
        url("../fonts/HelveticaNeueBold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("../fonts/HelveticaNeueHeavy.woff2") format("woff2"),
        url("../fonts/HelveticaNeueHeavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
	font-family: "Liberation Serif";
	src: url("../fonts/LiberationSerif-Italic.woff2") format("woff2"),
		url("../fonts/LiberationSerif-Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans-Regular.woff2") format("woff2"),
		url("../fonts/OpenSans-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans-SemiBold.woff2") format("woff2"),
		url("../fonts/OpenSans-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Open Sans";
	src: url("../fonts/OpenSans-Bold.woff2") format("woff2"),
		url("../fonts/OpenSans-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	src: url("../fonts/Raleway-Bold.woff2") format("woff2"),
		url("../fonts/Raleway-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: 'Noto Sans Malayalam';
    src: url('../fonts/NotoSansMalayalam.woff2') format('woff2'),
        url('../fonts/NotoSansMalayalam.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Noto Sans Malayalam';
    src: url('../fonts/NotoSansMalayalam-Bold.woff2') format('woff2'),
        url('../fonts/NotoSansMalayalam-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*------ Fonts End ------*/

/*------ Main ------*/

:root {
	--default-color: #903e97;
    --side-bar: #9B339C;
	--default-text: #262b2e;
	--default-grey: #676a6c;
	--default-dark-grey: #C3C3C3;
	--bg-color: #f2f2f2;
	--light-grey: rgb(38 43 46 / 70%);
	--border: #e6e6e6;
	--hover: #ac6eb1;
	--anchor: #878787;
	--filler-text: #d2d2d2;
	--placeholder: #e6e6e6;
	--white: #ffffff;
	--black: #000000;
	--dark: #3B3B3B;
	--dark-bg: #585858;
	--dark-lt-text: #585858;
	--sprite: url("../images/icons/sprite.svg");
	--sprite-dark: url("../images/icons/sprite-dark.svg");
	--social: url("../images/icons/social-group.svg");
	--dark-lt-grey: #686763;
}
* {
	box-sizing: border-box;
}
body {
	float: left;
	width: 100%;
	height: 100%;
	font-family: "Open Sans" !important;
	font-weight: normal;
	color: var(--default-text);
	background: var(--bg-color);
	position: fixed;
	top: 0px;
	left: 0px;
	margin: 0px;
}
.dark-mode {
	color: var(--white);
	background: var(--dark-bg);
}
#root {
	float: left;
	width: 100%;
	height: 100%;
}
.main-wrapper {
	float: left;
	width: 100%;
	height: 100%;
	overflow-y: auto;
}
.content-area {
	float: left;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;
}
.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.hide {
	display: none !important;
}
.hide-lg {
    display: none;
}
.general-btn {
	float: left;
	width: auto;
	height: 40px;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	color: var(--white);
	background: var(--default-color);
	padding: 0 20px;
	border-radius: 4px;
	line-height: 40px;
	display: flex;
	display: -webkit-flex;
  	display: -moz-box;
  	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	transition: 0.3s ease-out;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
}
.general-btn:hover {
	background: var(--hover);
	color: var(--white);
}
.general-btn.grey {
    background: var(--placeholder);
    color: var(--default-grey);
}
.font-bold, strong {
	font-weight: bold;
}
.ico-lib:before {
	content: "";
	background: var(--sprite) no-repeat;
	display: inline-block;
}
.dark-mode .ico-lib:before {
	background: var(--sprite-dark) no-repeat;
}
.loader-wrapper {
    float: left;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 60%);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    pointer-events: none;
    z-index: 10;
}

/*------ Main End ------*/

/*------ Navbar ------*/

.Navbar {
	float: left;
	width: 100%;
	height: 75px;
	background: var(--white);
	position: sticky;
	left: 0;
	top: 0;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
	z-index: 9;
}
.dark-mode .Navbar {
	background: var(--dark);
}
.Navbar .container {
	height: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
}
.navbar-container {
	float: left;
	width: 100%;
	height: 60px;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
}
.navbar-menu {
	width: 33.33%;
}
.brand-logo {
	float: left;
	width: 33.33%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.brand-logo img {
	float: left;
	width: 100px;
	/*height: 54px;*/
	cursor: pointer;
}
.menu {
	float: left;
	width: 18px;
	height: 15px;
	background: var(--sprite) no-repeat -104px -21px;
	position: relative;
	cursor: pointer;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
}
.dark-mode .menu {
	background: var(--sprite-dark) no-repeat -104px -21px;
}
.menu:hover {
	background-position: -104px -44px;
}
.navbar-right {
	flex-shrink: 0;
	width: 33.33%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;

}
.bookmark {
	float: left;
	width: auto;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 33px;
	color: var(--default-grey);
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	margin-left: 20px;
	position: relative;
}
.dark-mode .bookmark {
	color: var(--white);
}
.bookmark:after {
	content: "";
	width: 20px;
	height: 20px;
	background: var(--sprite) no-repeat -128px -19px;
	margin-left: 5px;
	display: inline-block;
}
.dark-mode .bookmark:after {
	background: var(--sprite-dark) no-repeat -128px -19px;
}
.bookmark:hover:after {
	background-position: -128px -42px;;
}
.search-block {
	float: left;
	width: 18px;
	height: 18px;
	margin-left: 22px;
}
.search-btn {
	width: 19px;
	height: 19px;
	background: var(--sprite) no-repeat -1px -20px;
	display: inline-block;
	position: relative;
}
.dark-mode .search-btn {
	background: var(--sprite-dark) no-repeat -1px -20px;
}
.search-btn:hover {
	background-position: -1px -43px;
}
.navbar-right .general-btn {
	height: 30px;
	font-size: 12px;
	font-weight: 600;
}
.signin {
	float: left;
	height: 18px;
	line-height: 18px;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	padding-left: 28px;
	margin-left: 22px;
	position: relative;
	cursor: pointer !important;
}
.signin:hover {
	color: var(--default-color);
}
.dark-mode .signin:hover {
	color: var(--white);
}
.signin:before {
	content: "";
	width: 21px;
	height: 22px;
	background: var(--sprite) no-repeat -24px -18px;
	position: absolute;
	left: 0;
	top: calc(50% - 11.5px);
}
.dark-mode .signin:before {
	background: var(--sprite-dark) no-repeat -24px -18px;
}
.signin:hover:before {
	background-position: -24px -41px;
}
.signin.active:before {
	background-position: -152px -19px;
}
.signin.active:hover:before {
	background-position: -152px -42px;
}
.content-area-inner {
	float: left;
	width: 100%;
	position: relative;
}
.aside-menu {
	float: left;
	width: 300px;
	height: 100%;
	background: var(--side-bar);
	position: fixed;
	left: -350px;
	top: 0;
	box-shadow: 1px 0 16px 0 rgb(0 0 0 / 30%);
	transition: 0.3s ease-out;
	z-index: 11;
}
.dark-mode .aside-menu {
    background: var(--dark);
}
.aside-menu.fly-in {
	left: 0;
}
.aside-menu:after {
	content: "";
	width: 268.5px;
	height: 302px;
	background: url(../images/icons/Bull.png) no-repeat;
	background-size: cover;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 1;
}
.aside-close {
	width: 16px;
	height: 16px;
	background: var(--sprite) no-repeat -87px -63px;
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 3;
}
.dark-mode .aside-close {
	background: var(--sprite-dark) no-repeat -87px -63px;
}
.aside-menu-container {
	float: left;
	width: 100%;
	height: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	flex-direction: column;
	-webkit-flex-direction: column;
	-moz-box-direction: column;
	-ms-flex-direction: column;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	position: relative;
	z-index: 2;
}
.aside-menu-top {
	align-self: baseline;
	float: left;
	width: 100%;
	height: 100%;
    padding-top: 170px;
}
.aside-menu-top-inner {
	float: left;
	width: 100%;
	height: 100%;
	padding: 0px 50px 0px 20px;
}
.aside-footer {
	float: left;
	width: 100%;
	padding: 30px 50px 20px 20px;
}
.sidebar-logo {
    position: absolute;
    left: 15px;
    top: 22px;
}
.sidebar-logo img {
    float: left;
    width: 175px;
}
.menu-listing li {
	float: left;
	width: 100%;
}
.menu-listing li a {
	float: left;
	width: auto;
	color: var(--white);
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 10px;
	cursor: pointer;
	transition: 0.3s ease-out;
	position: relative;
}
.menu-listing > li > a:before {
    content: "";
    width: 0;
    height: 1px;
    background: var(--white);
    position: absolute;
    left: 0;
    bottom: 0;
    transition: .3s ease-out;
}
.dark-mode .menu-listing > li > a:before {
	background: var(--default-color);
}
.menu-listing > li > a.non-link:before {
	content: none;
}
.menu-listing > li > a:hover:before,
.menu-listing > li.active > a:before {
    width: 100%;
}
.redirect {
	float: left;
	width: 100%;
	margin-bottom: 20px;
}
.redirect a {
	float: left;
	width: 100%;
	padding-left: 21px;
	font-size: 14px;
	line-height: 18px;
	color: var(--white);
	cursor: pointer;
	position: relative;
}
.redirect a:before {
	content: "";
	width: 11px;
	height: 11px;
	background: var(--sprite) no-repeat -71px -65px;
	position: absolute;
	left: 0;
	top: calc(50% - 6.5px);
}
.dark-mode .redirect a:before {
	background: var(--sprite-dark) no-repeat -71px -65px;
}
.other-info {
	float: left;
	width: 100%;
}
.other-info p {
	float: left;
	width: 100%;
	font-size: 14px;
	color: var(--white);
	line-height: 22px;
}
.other-info-button-block {
	float: left;
	width: 100%;
	margin-top: 20px;
}
.border-btn {
	float: left;
	width: auto;
	height: 40px;
	font-size: 14px;
	line-height: 36px;
	padding: 0 20px;
	border: 2px solid var(--white);
	color: var(--white);
	font-weight: 600;
	cursor: pointer;
	transition: 0.3s ease-out;
}
/* .dark-mode .border-btn {
	background: var(--white);
} */
.border-btn:hover {
	background: var(--white);
	color: var(--default-color);
}
.userIn .subscribe {
	display: none;
}
.userOut .subscribe {
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
}
.userOut .bookmark {
	display: none;
}
.overlay:before {
	content: "";
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 35%);
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
}
.search-wrapper {
	float: left;
	width: 100%;
	height: 80px;
	position: sticky;
	left: 0;
	top: 75px;
	background: #c79ecb;
	display: none;
	z-index: 1;
	transition: 0.3s ease-out;
}
.dark-mode .search-wrapper {
	background: #696969;
}
.search-wrapper.fly-in {
	display: block;
}
.search-input {
	float: left;
	width: 100%;
	height: 80px;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.search-input input {
	float: left;
	width: 50%;
	height: 40px;
	font-family: "Open Sans" !important;
	color: var(--default-text);
	border: 1px solid transparent;
	border-radius: 4px;
	padding: 0 20px;
	font-size: 16px;
	margin-right: 15px;
}
.close-panel {
    float: left;
    width: 16px;
    height: 15px;
    position: absolute;
    right: 20px;
    top: calc(50% - 7.5px);
}
.close-panel:before {
	content: "";
	width: 16px;
	height: 15px;
	background: url(../images/icons/close-white.svg) no-repeat;
	background-size: cover;
	display: inline-block;
}
.login-header .close-panel {
    right: 0;
    top: 0;
}
.login-header .close-panel:before {
	background: url(../images/icons/close-grey.svg) no-repeat;
	background-size: cover;
}
.menu-listing li .social-media-block {
	padding-left: 15px;
	margin-bottom: 10px;
}
.menu-listing li .social-media-block li {
    width: 100%;
    margin: 0;
}
.menu-listing li .social-media-block li a {
    margin: 0;
	padding: 0 0 0 25px;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.menu-listing li .social-media-block li a:hover {
	color: var(--white);
}
.menu-listing li .social-media-block li a:before {
    top: 4px;
}
.menu-listing li .web-ico:before {
    background: var(--social) no-repeat 0 0;
}
.menu-listing li .yt-ico:before {
    background: var(--social) no-repeat -29px -1px;
}
.menu-listing li .fb-ico:before {
    background: var(--social) no-repeat -18px 0;
}
.menu-listing li .tw-ico:before {
	background: var(--social) no-repeat -46px -1px;
}
.menu-listing li .ig-ico:before {
    background: var(--social) no-repeat -65px -1px;
}
.menu-listing li .ln-ico:before {
    background: var(--social) no-repeat -80px -1px;
}

/*------ Navbar End ------*/

/*------ Content Area ------*/

.section-left {
	float: left;
	width: 290px;
	height: calc(100vh - 135px);
	margin-right: 25px;
	position: sticky;
	left: 0;
	top: 105px;
}
.tab-menu {
	float: left;
	width: 100%;
	margin-bottom: 30px;
}
.tab-menu li {
	float: left;
	width: 100%;
}
.tab-menu li nav {
	float: left;
	width: 100%;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 35px;
	padding-left: 40px;
	transition: 0.3s ease-out;
	text-transform: uppercase;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	position: relative;
	cursor: pointer;
}
.tab-menu li nav:hover,
.tab-menu li.active nav {
	color: var(--default-color);
}
.dark-mode .tab-menu li nav:hover,
.dark-mode .tab-menu li.active nav {
	color: var(--white);
}
.tab-menu li.active nav {
	background: var(--white);
	box-shadow: 0 1px 2px 0 rgb(0 0 0 / 8%);
}
.dark-mode .tab-menu li.active nav {
	background: var(--dark);
}
.tab-menu li nav:after {
	content: "";
	width: 4px;
	height: 0;
	background: var(--default-color);
	position: absolute;
	left: 0;
	top: 0;
	transition: 0.3s ease-out;
}
.tab-menu li.active nav:after {
	height: 100%;
}
.all-stories:before {
	content: "";
	width: 17px;
	height: 13px;
	background: var(--sprite) no-repeat -39px -2px;
	position: absolute;
	left: 15px;
	top: calc(50% - 7.5px);
}
.dark-mode .all-stories:before {
	content: "";
	width: 17px;
	height: 13px;
	background: var(--sprite-dark) no-repeat -39px -2px;
}
.market-news:before {
	content: "";
	width: 14px;
	height: 14px;
	background:  var(--sprite) no-repeat -20px -1px;
	position: absolute;
	left: 15px;
	top: calc(50% - 8px);
}
.dark-mode .market-news:before {
	background:  var(--sprite-dark) no-repeat -20px -1px;
}
.investment-insights:before {
	content: "";
	width: 14px;
	height: 8px;
	background:  var(--sprite) no-repeat -1px -4px;
	position: absolute;
	left: 15px;
	top: calc(50% - 5px);
}
.dark-mode .investment-insights:before {
	background:  var(--sprite-dark) no-repeat -1px -4px;
}
.special-coverage:before {
	content: "";
	width: 15px;
	height: 14px;
	background:  var(--sprite) no-repeat -60px -1px;
	position: absolute;
	left: 15px;
	top: calc(50% - 9px);
}
.dark-mode .special-coverage:before {
	background:  var(--sprite-dark) no-repeat -60px -1px;
}
.hedge-highlights:before {
	content: "";
	width: 18px;
	height: 16px;
	background: var(--sprite) no-repeat -79px 0;
	position: absolute;
	left: 15px;
	top: calc(50% - 10px);
}
.dark-mode .hedge-highlights:before {
	background: var(--sprite-dark) no-repeat -79px 0;
}
.story-bookmark:before {
    content: "";
	width: 15px;
	height: 15px;
    background: var(--sprite) no-repeat -99px 0;
    position: absolute;
    left: 15px;
    top: calc(50% - 8.5px);
}
.dark-mode .story-bookmark:before {
    background: var(--sprite-dark) no-repeat -99px 0;
}
.social-media-wrapper {
    float: left;
    width: 100%;
    padding-left: 15px;
    margin-bottom: 30px;
}
.social-header {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}
.social-header h5 {
    float: left;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}
.social-media-block {
    float: left;
    width: 100%;
}
.social-media-block li {
    float: left;
    width: 100%;
    margin-bottom: 5px;
}
.social-media-block li a {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-left: 25px;
	line-height: 25px;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    position: relative;
    transition: 0.3s ease-out;
    text-transform: uppercase;
    cursor: pointer;
}
.social-media-block li a:hover {
	color: var(--default-color);
}
.dark-mode .social-media-block li a:hover {
	color: var(--white);
}
.social-media-block li a:before {
    top: 4px;
}
.web-ico:before {
    content: "";
    width: 15px;
    height: 16px;
    background: var(--sprite) no-repeat -32px -88px;
    position: absolute;
    left: 4px;
    top: calc(50% - 8px);
    zoom: .8;
}
.dark-mode .web-ico:before {
    background: var(--sprite-dark) no-repeat -32px -88px;
}
.yt-ico:before {
    content: "";
    width: 14px;
    height: 14px;
    background: var(--sprite) no-repeat -61px -89px;
    position: absolute;
    left: 2px;
    top: calc(50% - 8px);
}
.dark-mode .yt-ico:before {
    background: var(--sprite-dark) no-repeat -61px -89px;
}
.fb-ico:before {
    content: "";
    width: 9px;
    height: 15px;
    background: var(--sprite) no-repeat -50px -88px;
    position: absolute;
    left: 4px;
    top: calc(50% - 8.5px);
}
.dark-mode .fb-ico:before {
    background: var(--sprite-dark) no-repeat -50px -88px;
}
.tw-ico:before {
	content: "";
	width: 17px;
	height: 13px;
	background: var(--sprite) no-repeat -78px -89px;
	position: absolute;
	left: 0;
	top: calc(50% - 6.5px);
}
.dark-mode .tw-ico:before {
	background: var(--sprite-dark) no-repeat -78px -89px;
}
.ig-ico:before {
    content: "";
    width: 13px;
    height: 13px;
    background: var(--sprite) no-repeat -97px -89px;
    position: absolute;
    left: 1px;
    top: calc(50% - 7.5px);
}
.dark-mode .ig-ico:before {
    background: var(--sprite-dark) no-repeat -97px -89px;
}
.ln-ico:before {
    content: "";
    width: 13px;
    height: 13px;
    background: var(--sprite) no-repeat -112px -89px;
    position: absolute;
    left: 1px;
    top: calc(50% - 7.5px);
}
.dark-mode .ln-ico:before {
    background: var(--sprite-dark) no-repeat -112px -89px;
}
.section-main {
	float: left;
	width: calc(100% - 630px);
	min-height: 100px;
}
.section-main h4 {
	display: none;
}
.each-grid {
    float: left;
    width: 100%;
    background: var(--white);
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 8%);
    margin-bottom: 10px;
}
.dark-mode .each-grid {
	background: var(--dark);
}
.each-grid:last-child {
	margin-bottom: 0;
}
.grid-description {
	float: left;
	width: 100%;
	padding: 20px;
}
.grid-description-header {
	float: left;
	width: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	margin-bottom: 15px;
}
.grid-description-header > p {
	font-size: 12px;
	font-weight: 700;
	color: var(--default-grey);
	text-transform: uppercase;
}
.dark-mode .grid-description-header > p {
	color: var(--white);
}
.tag-type {
	float: left;
	width: auto;
	height: 28px;
	font-size: 10px;
	font-weight: 700;
	padding: 0 10px;
	border: 1px solid var(--border);
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	text-transform: uppercase;
}
.tag-type.market-news:before {
    position: relative;
    left: 0;
    top: 0;
	margin-right: 5px;
}
.tag-type.investment-insights:before {
    position: relative;
    left: 0;
    top: 0;
	margin-right: 5px;
}
.tag-type.hedge-highlights:before {
    position: relative;
    left: 0;
    top: 0;
	margin-right: 5px;
}
.tag-type.special-coverage:before {
    position: relative;
    left: 0;
    top: 0;
	margin-right: 5px;
}
.grid-description-wrapper {
	float: left;
	width: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: stretch;
	-webkit-align-items: stretch;
	-moz-box-align: stretch;
	-ms-align-items: stretch;
	margin-bottom: 15px;
}
.grid-image {
    float: left;
    width: 100%;
    height: 320px;
	margin-bottom: 20px;
}
.grid-image > a {
	cursor: pointer;
}
.grid-image .lazy-load-image-background {
    float: left;
    width: 100%;
    height: 100%;
}
.grid-image img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
	border: 1px solid #f2f2f2;
}
.dark-mode .grid-image img {
	border-color: transparent;
}
.grid-description-content {
	float: left;
	width: 100%;
}
.grid-description-content h5 {
    float: left;
    width: 100%;
    font-size: 20px;
	font-family: 'Noto Sans Malayalam';
    font-weight: bold;
    line-height: 1.7;
    margin-bottom: 10px;
}
.grid-description-content h5 a {
    transition: .3s ease-out;
	cursor: pointer;
}
.grid-description-content h5 a:hover {
    color: var(--default-color);
}
.grid-description-content ul {
	float: left;
	width: 100%;
	list-style: disc;
	padding-left: 19px;
}
.grid-description-content ul li {
	float: left;
	width: 100%;
	font-size: 14px;
	color: var(--default-grey);
	line-height: 19px;
	margin-bottom: 2px;
}
.grid-description-content > p {
    float: left;
    width: 100%;
    color: var(--default-grey);
	font-family: 'Noto Sans Malayalam';
    font-size: 15px;
    line-height: 25px;
}
.dark-mode .grid-description-content > p {
	color: var(--default-dark-grey);
}
.grid-description-content > p ol {
    float: left;
    width: 100%;
}
.grid-description-content > p ol li {
    float: left;
    width: 100%;
    word-break: break-word;
}
.grid-description-content > p > a {
    cursor: pointer;
}
.grid-description-footer {
	float: left;
	width: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
}
.grid-option {
	float: left;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
}
.grid-option .bookmark,
.grid-option .bookmark:after {
	margin: 0;
}
.grid-option .bookmark:after {
	width: 15px;
	height: 15px;
	background: var(--sprite) no-repeat -49px -22px;
}
.dark-mode .grid-option .bookmark:after {
	background: var(--sprite-dark) no-repeat -49px -22px;
}
.grid-option .bookmark:hover:after, .grid-option .bookmark.active:after {
	background-position: -49px -45px;
}
.share {
	float: left;
	width: auto;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 33px;
	color: var(--default-grey);
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	margin-left: 15px;
	position: relative;
}
.share:before {
	content: "";
	width: 15px;
	height: 15px;
	background: var(--sprite) no-repeat -68px -21px;
	display: inline-block;
}
.dark-mode .share:before {
	background: var(--sprite-dark) no-repeat -68px -21px;
}
.share:hover:before {
	background-position: -68px -44px;
}
.grid-description-footer nav,
.grid-description-footer a {
	font-size: 12px;
	font-weight: 700;
	color: var(--default-color);
	margin-left: 20px;
	cursor: pointer;
}
.dark-mode .grid-description-footer nav,
.dark-mode .grid-description-footer a {
	color: var(--white);
}
.section-right {
	float: left;
	width: 290px;
	margin-left: 25px;
	position: sticky;
	left: 0;
	top: 105px;
}
.each-adv {
	float: left;
	width: 100%;
}
.each-adv img {
	float: left;
	width: 100%;
	object-fit: contain;
}
.filter-wrapper {
	float: left;
	width: 100%;
	background: var(--white);
}
.dark-mode .filter-wrapper {
	background: var(--dark);
}
.filter-header {
    float: left;
    width: 100%;
    height: auto;
    min-height: 40px;
    padding: 15px 15px 15px 40px;
	position: relative;
    cursor: pointer;
}
.filter-header h5 {
	float: left;
	width: 100%;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 5px;
	position: relative;
}
.filter-header p {
	float: left;
	width: 100%;
	font-size: 14px;
	line-height: normal;
	color: var(--default-grey);
}
.dark-mode .filter-header p {
	color: var(--default-dark-grey);
}
.filter-header:before {
	content: "";
	width: 15px;
	height: 13px;
	background: url(../images/icons/filter-nav-theme.svg) no-repeat;
	transition: 0.3s ease-out;
	background-size: cover;
	margin-right: 10px;
	position: absolute;
	left: 15px;
	top: 15px;
}
.dark-mode .filter-header:before {
	background: url(../images/icons/filter-nav-dark.svg) no-repeat;
	background-size: cover;
}
.filter-header h5:after {
	content: "";
	width: 13px;
	height: 8px;
	background: var(--sprite) no-repeat -51px -68px;
	position: absolute;
	right: 0;
	top: calc(50% - 4px);
}
.dark-mode .filter-header h5:after {
	background: var(--sprite-dark) no-repeat -51px -68px;
}
.filter-panel {
	float: left;
	width: 100%;
	display: none;
}
.filter-panel.fly-in {
	display: block;
}
.filter-mob-panel {
    display: none;
}
.filter-panel-body {
	float: left;
	width: 100%;
}
.filter-panel-body-inner {
	float: left;
	width: 100%;
	padding: 10px 15px 20px 15px;
}
.each-filter-panel {
	float: left;
	width: 100%;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid var(--border);
}
.dark-mode .each-filter-panel {
	border-color: var(--dark-lt-text);
}
.each-filter-panel.overlay-hidden {
    pointer-events: none;
}
.each-filter-panel:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0;
}
.each-filter-panel > h5 {
	float: left;
	width: 100%;
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 20px;
	text-transform: uppercase;
}
.date-range-block {
	float: left;
	width: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
}
.date-picker {
	float: left;
	width: calc((100% / 2) - 7px);
	margin-right: 15px;
	position: relative;
}
.date-picker input {
	float: left;
	width: 100%;
	height: 35px;
	font-family: "Open Sans" !important;
	background: transparent;
	font-size: 12px;
	border: 2px solid var(--border);
	padding: 0 10px 0 32px;
}
.dark-mode .date-picker input {
	color: var(--white);
	border-color: var(--dark-lt-text);
}
.date-picker:last-child {
	margin-right: 0;
}
.date-picker:before {
	content: "";
	width: 12px;
	height: 12px;
	background: var(--sprite) no-repeat -87px -23px;
	position: absolute;
	left: 12px;
	top: calc(50% - 7px);
}
.dark-mode .date-picker:before {
	background: var(--sprite-dark) no-repeat -87px -23px;
}
.date-picker .react-datepicker-wrapper {
    float: left;
    width: 100%;
    height: 35px;
}
.date-range-block .react-datepicker__close-icon::after {
    background: transparent;
    color: #E53935;
    font-size: 23px;
}
.checkbox-wrapper {
	float: left;
	width: 100%;
}
.checkbox {
	float: left;
	width: 100%;
	margin-bottom: 10px;
}
.checkbox-wrapper .checkbox:last-child {
	margin-bottom: 0;
}
.checkbox > label {
	float: left;
	width: 100%;
	font-size: 14px;
	line-height: 20px;
	padding-left: 30px;
	position: relative;
}
.checkbox input {
	float: left;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}
.control-indicator {
	float: left;
	width: 20px;
	height: 20px;
	border: 2px solid #bdbdbd;
	background: var(--white);
	position: absolute;
	left: 0;
	top: 0;
}
.dark-mode .control-indicator {
	border-color: var(--dark-lt-text);
	background: var(--dark);
}
.checkbox > label .control-indicator:after {
	content: "";
	background: url(../images/icons/check-white.svg) no-repeat;
	background-size: cover;
	width: 11px;
	height: 8px;
	position: absolute;
	left: 3px;
	top: 4px;
	display: none;
}
.checkbox input:checked ~ .control-indicator {
	background: var(--default-color);
	border-color: var(--default-color);
}
.checkbox input:checked ~ .control-indicator:after {
	display: block;
}
.filter-bottom {
	float: left;
	width: 100%;
	padding: 20px 15px;
	background: var(--white);
	position: sticky;
	left: 0;
	bottom: 0;
	z-index: 1;
}
.dark-mode .filter-bottom {
	background: var(--dark);
}
.filter-bottom .border-btn {
	width: 100%;
	border-color: var(--default-text);
	color: var(--default-text);
	font-weight: 700;
}
.dark-mode .filter-bottom .border-btn {
	border-color: var(--dark-lt-text);
	color: var(--dark-lt-text);

}
.filter-bottom .border-btn:hover {
	border-color: var(--default-color);
	background: var(--default-color);
	color: var(--white);
}
.advertisement-cntnr {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    margin: 20px 0;
}
.advertisement-cntnr img {
    float: left;
    width: 100%;
    max-width: 300px;
}
.article-content-details iframe {
    float: left;
    width: 100%;
    height: 360px;
}
.mob-close {
    float: left;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 13px;
	display: none;
	z-index: 2;
}
.mob-close:before {
	content: "";
    float: left;
    width: 15px;
    height: 14px;
    background: url(../images/icons/close-grey.svg) no-repeat;
    background-size: cover;
}
.infinite-scroll-component {
    overflow: unset !important;
}
.story-next button, .story-next a {
    float: left;
    width: 35px;
    height: 35px;
    background: var(--hover);
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.story-next button:first-child, .story-next a:first-child {
    border-radius: 24px 0 0 24px;
	border-right: 1px solid rgb(255 255 255 / 50%);
}
.story-next button:last-child, .story-next a:last-child {
    border-radius: 0 24px 24px 0;
}
.prev-story:before {
	content: "";
	background: url("../images/icons/select-arrow-white.svg") no-repeat;
	background-size: cover;
	width: 13px;
	height: 8px;
	display: inline-block;
	transform: rotate(90deg);
}
.next-story:before {
	content: "";
	background: url("../images/icons/select-arrow-white.svg") no-repeat;
	background-size: cover;
	width: 13px;
	height: 8px;
	display: inline-block;
	transform: rotate(270deg);
}
.filter-wrapper .date-picker:last-child .react-datepicker-popper {
    left: auto !important;
    right: 0;
}

/*------ Content Area End ------*/

/*------ Detail Page ------*/

.anchor-links {
	float: left;
	width: 100%;
	color: var(--anchor);
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	margin-bottom: 10px;
}
.anchor-links a {
	font-size: 14px;
	margin: 0 5px;
	transition: 0.3s ease-out;
	cursor: pointer;
}
.anchor-links a:first-child {
	margin-left: 0;
}
.anchor-links a:hover {
	color: var(--default-color);
}
.anchor-links a.current-page {
	color: var(--default-text);
}
.dark-mode .anchor-links a.current-page {
	color: var(--white);
}
.article-details-wrapper {
	float: left;
	width: 100%;
	background: var(--white);
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgb(0 0 0 / 8%);
}
.dark-mode .article-details-wrapper {
    background: var(--dark);
}
.article-image {
	float: left;
	width: 100%;
	height: 320px;
	position: relative;
}
.article-image .lazy-load-image-background {
    float: left;
    width: 100%;
    height: 100%;
}
.article-image img {
	float: left;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px 4px 0 0;
}
.article-details {
	float: left;
	width: 100%;
	padding: 20px;
}
.article-header {
	float: left;
	width: 100%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	margin-bottom: 20px;
}
.article-content {
	float: left;
	width: 100%;
}
.article-content * {
	font-family: 'Noto Sans Malayalam';
}
.article-content h3 {
	float: left;
	width: 100%;
	font-weight: bold;
	font-size: 29px;
	line-height: 37px;
	margin-bottom: 20px;
}
.article-content ul {
	float: left;
	width: 100%;
	list-style-type: disc;
	padding-left: 20px;
	margin-bottom: 20px;
}
.article-content ul li {
	float: left;
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 5px;
	color: var(--default-grey);
}
.article-content p {
	/* float: left; */
	width: 100%;
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 20px;
}
/* .article-content p a {
    color: var(--default-color);
} */
.article-content .full-story p:last-child {
	margin-bottom: 0;
}
.article-content video {
	float: left;
	width: 100%;
	margin-bottom: 20px;
}
.article-content blockquote {
	float: left;
	width: 100%;
	border-top: 1px solid var(--border);
	border-bottom: 1px solid var(--border);
	padding: 40px 40px 40px 80px;
	margin-top: 10px;
	margin-bottom: 30px;
	position: relative;
}
.article-content blockquote:before {
	content: "";
	background: url(../images/icons/quotes.svg) no-repeat;
	background-size: cover;
	width: 41px;
	height: 41px;
	position: absolute;
	left: 25px;
	top: 40px;
}
.article-content blockquote h5 {
	float: left;
	width: 100%;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 31px;
	margin-bottom: 15px;
}
.article-content blockquote p {
	float: left;
	width: 100%;
	font-family: "Liberation Serif";
	color: var(--default-grey);
	font-size: 20px;
	line-height: 26px;
}
.article-content figure {
	float: left;
	width: 100%;
	margin-bottom: 20px;
}
.article-content figure img,
.article-content p img {
	float: left;
	width: 100%;
	object-fit: contain;
}
.boiler-panel {
	float: left;
	width: 100%;
	background: var(--white);
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgb(0 0 0 / 8%);
}
.dark-mode .boiler-panel {
	background: var(--dark);
}
.boiler-banner {
    float: left;
    width: 100%;
    height: 175px;
    background: var(--default-color);
    border-radius: 4px 4px 0 0;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    flex-direction: column;
	-webkit-flex-direction: column;
	-moz-box-direction: column;
	-ms-flex-direction: column;
    justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
    padding: 20px;
    position: relative;
}
.dark-mode .boiler-banner {
	background: var(--dark);
}
.boiler-banner:after {
	content: "";
	background: url(../images/icons/bull-dark.png) no-repeat;
	background-size: cover;
	width: 405px;
	height: 175px;
	position: absolute;
	right: 0;
	bottom: 0;
}
.dark-mode .boiler-banner:after {
    filter: invert(1);
}
.boiler-banner p {
    font-size: 14px;
    line-height: 19px;
    color: var(--white);
}
.boiler-banner h5 {
	float: left;
	width: 100%;
	font-style: normal;
	font-weight: bold;
	color: var(--white);
	font-size: 24px;
	line-height: 28px;
}
.boiler-content {
	float: left;
	width: 100%;
	padding: 20px;
}
.boiler-content > p {
	float: left;
	width: 100%;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 10px;
	word-break: break-word;
}
.boiler-content > p a {
    color: var(--default-color);
}
.boiler-content video {
	float: left;
	width: 100%;
	margin-bottom: 20px;
}
.price-tag-box {
	float: left;
	width: 100%;
}
.price-tag-box li .price-tag-box-anchor {
	float: left;
	width: 100%;
	height: 120px;
	background: #f7f7f7;
	border-radius: 4px;
	padding: 15px 40px 10px;
	/*display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;*/
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	box-shadow: 0px 0.742515px 1.48503px rgba(0, 0, 0, 0.08);
	margin-bottom: 15px;
	transition: .3s ease-out;
}
.offer-percent {
	margin-left: 10px;
	color: #66E929;
	font-size: 22px !important;
}
.price-offer {
	width: 75%;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.price-details {
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	align-items: center;
	margin-top: 10px;
}
.offered-price {
	width: 25%;
}
.price-tag-box li .price-tag-box-anchor:has(.priceTag__remark) {
	position: relative;
	padding-bottom: 35px;
}
.priceTag__remark {
	position: absolute;
	bottom: 10px;
}
.priceTag__remark p {
	font-size: 12px;
	line-height: 12px;
}
.dark-mode .price-tag-box li .price-tag-box-anchor {
	background: var(--dark-lt-grey);
}
.price-tag-box li .price-tag-box-anchor:hover .price-tag-left h5 {
    color: var(--default-color);
}
.dark-mode .price-tag-box li .price-tag-box-anchor:hover {
    background: var(--default-color);
}
.dark-mode .price-tag-box li .price-tag-box-anchor:hover .price-tag-left h5 {
    color: var(--white);
}
.price-tag-box li:last-child {
	margin-bottom: 0;
}
.price-tag-left h5 {
    font-weight: bold;
    font-size: 29.1856px;
    line-height: 36px;
    transition: .3s ease-out;
    color: var(--default-grey);
	text-align: center;
}
.dark-mode .price-tag-left h5 {
    color: var(--white);
}
.price-tag-left p {
	font-weight: bold;
	font-size: 31.1856px;
	line-height: 36px;
	margin-bottom: 0;
}
.strike {
	text-decoration: line-through;
	color: var(--default-grey);
}
.offered-price h5 {
	font-size: 24px;
}
.normal {
	font-weight: normal;
}
.superscript {
	position: relative;
	top: -0.4em;
	font-size: 80%;
	color: var(--default-grey);
	font-weight: normal;
}
.dark-mode .price-tag-left p {
	color: var(--white);
}
.dark-mode .price-tag-left p.price-offer {
	color: var(--white);
}

.price-tag-right h5 {
	font-weight: bold;
	font-size: 31.1856px;
	line-height: 36px;
	color: var(--default-grey);
	white-space:nowrap;
}
.dark-mode .price-tag-right h5 {
	color: var(--white);
}
.price-tag-right p {
	font-size: 16px;
	line-height: 22px;
	color: var(--default-color);
	margin-bottom: 0;
	white-space:nowrap;
}
.dark-mode .price-tag-right p {
	color: var(--white);
}
.dark-mode .price-tag-left p.offer-percent {
	color: #66E929;
}
.dark-mode .strike,
.dark-mode .superscript{
	color: var(--white);
}
.boiler-nav-tab {
    float: left;
    width: 100%;
}
.boiler-nav {
    float: left;
    width: 100%;
    height: 50px;
    border-bottom: 4px solid var(--border);
}
.boiler-nav a {
    float: left;
    width: auto;
    height: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    font-size: 12px;
    padding: 0 20px;
    position: relative;
    cursor: pointer;
}
.boiler-nav a.active {
    font-weight: 600;
}
.boiler-nav a:before {
    content: "";
    width: 0;
    height: 4px;
    background: var(--default-color);
    position: absolute;
    left: 0;
    bottom: -4px;
    transition: .3s ease-out;
}
.boiler-nav a.active:before {
    width: 100%;
}
.form-container {
    float: left;
    width: 100%;
}
.each-input-field {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    flex-direction: column;
	-webkit-flex-direction: column;
	-moz-box-direction: column;
	-ms-flex-direction: column;
}
.each-input-field > label {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
}
.each-input-field input {
    float: left;
    width: 100%;
    height: 40px;
    border: 0;
    border-bottom: 1px solid var(--border);
    font-weight: bold;
    font-size: 17px;
    transition: .3s ease-out;
}
.dark-mode .each-input-field input {
    border-color: var(--dark-lt-text);
    background: var(--dark);
	color: var(--white);
}
.each-input-field input:focus {
    border-color: var(--default-color);
}
.each-input-field textarea {
    float: left;
    width: 100%;
    height: 120px;
    border: 0;
    border-bottom: 1px solid var(--border);
    font-weight: bold;
    font-size: 17px;
    resize: none;
    transition: .3s ease-out;
}
.dark-mode .each-input-field textarea {
    background: var(--dark);
    border-color: var(--dark-lt-text);	
	color: var(--white);
}
.each-input-field textarea:focus {
    border-color: var(--default-color);
}
.select-box {
    float: left;
    width: 100%;
    position: relative;
}
.select-box:after {
    content: "";
    width: 13px;
    height: 8px;
    background: var(--sprite) no-repeat -33px -68px;
	position: absolute;
	right: 20px;
	top: calc(50% - 3.5px);
}
.dark-mode .select-box:after {
    background: var(--sprite-dark) no-repeat -33px -68px;
}
.select-box select {
    float: left;
    width: 100%;
    height: 40px;
    padding: 0 40px 0 0;
    border: 0;
	background: var(--white);
    border-bottom: 1px solid var(--border);
    font-weight: bold;
    font-size: 17px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: '';
    transition: .3s ease-out;
}
.dark-mode .select-box select {
	background: transparent;
	color: var(--white);
	border-color: var(--dark-lt-text);
}
.select-box select option {
	font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
}
.dark-mode .select-box select option {
	background: var(--dark);
}
.form-submit-block {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
}
.form-submit-block .border-btn {
    border-color: var(--default-color);
    color: var(--default-color);
	margin-left: 10px;
}
.form-submit-block .border-btn:hover {
    border-color: var(--default-color);
    background: var(--default-color);
    color: var(--white);
}
.error-field {
    float: left;
    width: 100%;
    font-size: 16px;
	line-height: normal;
    margin: 10px 0 0 0;
    color: #f44336;
}
.alt-login {
    float: left;
    width: 100%;
    margin-top: 20px;
}
.divider-text {
    float: left;
    width: 100%;
    height: 28px;
    position: relative;
    margin-bottom: 25px;
}
.divider-text:after {
    content: "";
    width: 100%;
    height: 1px;
    background: #eaeaea;
    position: absolute;
    left: 0;
    top: calc(50% - .5px);
}
.dark-mode .divider-text:after {
    background: var(--dark-lt-text);
}
.divider-text span {
    float: left;
    width: 28px;
    height: 28px;
    background: var(--white);
    float: left;
    border-radius: 50%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    font-size: 12px;
    color: var(--default-grey);
    position: absolute;
    top: calc(50% - 14px);
    left: calc(50% - 14px);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    text-transform: uppercase;
    z-index: 1;
}
.dark-mode .divider-text span {
	background: var(--dark-lt-text);
	color: var(--white);
}
.alt-login-box {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.gl {
    background-color: #dd4b39 !important;
    height: 45px;
    padding: 0 20px !important;
    border-radius: 5px !important;
    margin-right: 5px;
    color: var(--white) !important;
    font-family: "Open Sans" !important;
	transition: .3s ease-out;
}
.gl:hover {
	background-color: rgb(221 75 57 / 75%) !important ;
}
.gl span {
    padding: 0 !important;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.gl span:before {
	content: "";
	background: var(--sprite) no-repeat -108px -64px;
	width: 20px;
	height: 14px;
	display: inline-block;
	margin-right: 5px;
}
.dark-mode .gl span:before {
	background: var(--sprite-dark) no-repeat -108px -64px;
}
.fb {
    float: left;
    width: auto;
    height: 45px;
    padding: 0 20px;
    background: #3b5998;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    margin-left: 5px;
    font-family: "Open Sans" !important;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	transition: .3s ease-out;
}
.fb:before {
	content: "";
	background: var(--sprite) no-repeat -130px -64px;
	width: 7px;
	height: 13px;
	display: inline-block;
	margin-right: 8px;
}
.dark-mode .fb:before {
	background: var(--sprite-dark) no-repeat -130px -64px;
}
.fb:hover {
    background: rgb(59 89 152 / 75%);
}
.terms-condition {
    float: left;
    width: 100%;
}
.terms-condition p {
    float: left;
    width: 100%;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
}
.terms-condition p a {
	color: var(--default-color);
}
.article-content-details h2 {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.form-container.contact {
    padding: 20px;
}
.form-container.contact > p {
    float: left;
    width: 100%;
    font-size: 14px;
	font-weight: 600;
    color: var(--default-grey);
    margin-bottom: 30px;
}
.article-content-details ol {
    float: left;
    width: 100%;
}
.article-content-details ol li {
    float: left;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    color: var(--default-grey);
    word-break: break-word;
}
.my-account-block {
    float: left;
    width: 100%;
}
.account-header {
    float: left;
    width: 100%;
    height: auto;
    padding: 20px;
    background: var(--hover);
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    flex-direction: column;
	-webkit-flex-direction: column;
	-moz-box-direction: column;
	-ms-flex-direction: column;
    margin-bottom: 30px;
	position: relative;
}
.account-thumb {
    float: left;
    width: 100px;
    height: 100px;
    background: var(--white);
    border-radius: 50%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    margin-bottom: 20px;
}
.account-thumb h5 {
    font-size: 50px;
    color: var(--hover);
}
.account-details h5 {
    float: left;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: var(--white);
    font-size: 16px;
    margin-bottom: 10px;
}
.account-details p {
    float: left;
    width: 100%;
    text-align: center;
    color: var(--white);
    font-size: 14px;
    margin-bottom: 10px;
}
.profile-edit-block {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    margin-top: 16px;
}
.profile-edit-block .border-btn {
	background: transparent;
}
.account-body {
    float: left;
    width: 100%;
}
.small-header {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border);
}
.dark-mode .small-header {
	border-color: var(--dark-lt-text);
}
.small-header h5 {
    float: left;
    width: 100%;
	color: var(--default-color);
    font-weight: 600;
}
.dark-mode .small-header h5 {
	color: var(--white);
}
.account-inner-content {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}
.account-inner-content p {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    color: var(--default-grey);
    margin-bottom: 10px;
}
.dark-mode .account-inner-content p {
	color: var(--white);
}
.button-group {
    float: left;
    width: 100%;
	margin-top: 10px;
}
.button-group .each-btn {
    float: left;
    width: auto;
    height: 40px;
    padding: 0 15px;
    background: var(--default-color);
    color: var(--white);
	font-size: 12px;
    margin-right: 10px;
    border-radius: 4px;
}
.each-btn.cancel {
    background: var(--anchor);
}
.each-btn.upgrade {
    background: #4caf50;
}
.account-inner-content .checkbox-wrapper {
    margin: 15px 0px;
}
.account-inner-content:last-child {
    margin-bottom: 0;
}
.article-image .lazy-load-image-background {
    float: left;
    width: 100%;
    height: 100%;
}
.close-block {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
    margin: 20px 0 0;
    position: sticky;
    left: 0;
    bottom: 20px;
}
.close-story {
    float: left;
    width: auto;
    height: 35px;
    line-height: 35px;
    border-radius: 24px;
    padding: 0 15px;
    background: var(--hover);
    color: var(--white);
	font-family: "Open Sans";
    font-size: 14px;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
	cursor: pointer;
}
.close-story:hover {
    color: var(--white);
}
.close-story:before {
    content: "";
    width: 10px;
    height: 10px;
    background: url(../images/icons/close-white.svg) no-repeat;
    background-size: cover;
    display: inline-block;
	margin-right: 5px;
}
.grid-option {
    float: left;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
}
.share-story {
    float: left;
    position: relative;
}
.share-container {
    float: left;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 25px;
    border-radius: 7px;
    background: var(--white);
    box-shadow: 0 7px 10px 0 rgb(0 0 0 / 40%);
	z-index: 1;
}
.inner-area.white-panel {
    float: left;
    width: 100%;
}
.white-pd-block {
    float: left;
    width: 100%;
    display: flex;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
}
.inner-area.white-panel > button {
    float: left;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 6px;
    top: 5px;
}
.white-pd-block button {
    float: left;
    margin-right: 10px;
}
.white-pd-block button:last-child {
    margin-right: 0;
}
.inner-area.white-panel > button:before {
	content: "";
    float: left;
    width: 10px;
    height: 9px;
    background: url(../images/icons/close-grey.svg) no-repeat;
    background-size: cover;
}
.date-picker .react-datepicker {
    background: var(--white);
    border: 1px solid var(--border);
    box-shadow: 0 5px 7px 0 rgb(0 0 0 / 15%);
}
.date-picker .react-datepicker__header {
    background: var(--white);
    padding-bottom: 8px;
    color: var(--default-color);
    border-bottom: 1px solid var(--border);
}
.date-picker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.date-picker .date-picker .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    display: none;
}
.date-picker .react-datepicker__navigation--previous {
    border-right-color: var(--border);
    top: 9px;
}
.date-picker .react-datepicker__navigation--next {
    border-left-color: var(--border);
    top: 9px;
}
.date-picker .react-datepicker__month .react-datepicker__month-text,
.date-picker .react-datepicker__month .react-datepicker__quarter-text {
    padding: 5px;
}
.date-picker .react-datepicker-popper {
    min-width: 151px;
}
.date-picker .react-datepicker__month--selected,
.date-picker .react-datepicker__month--in-selecting-range,
.date-picker .react-datepicker__month--in-range,
.date-picker .react-datepicker__quarter--selected,
.date-picker .react-datepicker__quarter--in-selecting-range,
.date-picker .react-datepicker__quarter--in-range {
    background-color: var(--default-color);
    transition: .3s ease-out;
}
.date-picker .react-datepicker__month-text.react-datepicker__month--selected:hover,
.date-picker .react-datepicker__month-text.react-datepicker__month--in-range:hover,
.date-picker .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.date-picker .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.date-picker .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.date-picker .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.date-picker .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.date-picker .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background: var(--hover);
}
.date-picker .react-datepicker__day--keyboard-selected,
.date-picker .react-datepicker__month-text--keyboard-selected,
.date-picker .react-datepicker__quarter-text--keyboard-selected,
.date-picker .react-datepicker__year-text--keyboard-selected {
    background-color: var(--default-color);
}
.date-picker .react-datepicker__month-text:hover,
.date-picker .react-datepicker__quarter-text:hover {
    background: var(--hover);
}
.story-placeholder {
    float: left;
    width: 100%;
    height: 300px;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
}
.story-placeholder p {
    font-size: 30px;
    font-weight: 600;
    color: #d2d2d2;
}
.resend-block {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.resend-block p {
	font-size: 14px;
	color: var(--default-grey);
}
.timer {
    color: var(--default-color);
	font-weight: 600;
}
.mob-ad {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}
.mob-ad a {
    float: left;
    width: 100%;
}
.mob-ad a .lazy-load-image-background {
    float: left;
    width: 100%;
}
.mob-ad img {
    float: left;
    width: 100%;
}
.search-wrapper.fly-in + .content-area .section-left {
    height: calc(100vh - 215px);
    top: 185px;
}
.search-wrapper.fly-in + .content-area .section-right {
    top: 185px;
}
.each-input-field .react-date-picker__wrapper {
    border: 0;
}
.each-input-field .react-date-picker {
    border-bottom: 1px solid var(--border);
	max-width: 100%;
}
.each-input-field .react-date-picker__inputGroup {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 17px;
    color: var(--black);
}
.each-input-field .react-date-picker__inputGroup input {
    border: 0;
}
.each-input-field .react-date-picker__inputGroup__input:invalid {
	background: transparent;
}
.each-input-field .react-date-picker__calendar .react-calendar {
    border-top-left-radius: 0 !important;
}
.each-input-field .react-calendar {
    border: 0;
    border-radius: 4px;
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
    border-top-left-radius: 0 !important;
}
.each-input-field .react-calendar > * {
    font-family: 'Open Sans';
}
.each-input-field .react-calendar__tile {
    max-width: 100%;
    height: 44px;
}
.each-input-field .react-calendar__tile--now {
    background: var(--default-color);
    color: var(--white);
}
.each-input-field .react-calendar__tile:disabled {
    background: var(--white);
    opacity: .5;
    cursor: no-drop;
}
.each-input-field .react-calendar__tile--now:enabled:hover,
.each-input-field .react-calendar__tile--now:enabled:focus {
    background: var(--hover);
}
.each-input-field .react-calendar__navigation button[disabled] {
    background: var(--white);
    opacity: .5;
    cursor: no-drop;
}
.each-input-field .react-calendar__navigation button:enabled:hover,
.each-input-field .react-calendar__navigation button:enabled:focus {
    background: #e7e7e7;
}
.each-input-field .react-calendar__navigation__label {
    font-weight: 600;
}
.each-input-field .each-input-field .react-date-picker__inputGroup  .react-date-picker__inputGroup__day {
    margin-left: 0;
}
.each-input-field .react-calendar button {
	font-weight: 600;
	font-size: 15px;
}
.account-inner-content p.failed-status {
	color: #f53232;
	font-size: 16px;
	margin-bottom: 20px;
	line-height: normal;
}
.article-header > p {
    font-size: 12px;
    color: var(--default-grey);
    font-weight: 700;
    text-transform: uppercase;
}
.dark-mode .article-header > p {
    color: var(--white);
}
.article-extra-block {
    float: left;
    width: 100%;
    margin: 20px 0 0px 0px;
    display: flex;
    justify-content: flex-end;
}
.each-input-field.with-code {
    position: relative;
}
.with-code .country-code {
    position: absolute;
    left: 0;
    top: 30px;
    font-weight: bold;
}
.each-input-field.with-code input {
    padding-left: 45px;
}
.seo-data {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}
.grid-description-content > p iframe {
    float: left;
    width: 100%;
    height: 315px;
}

/*------ Detail Page End ------*/

/*------ Login Page ------*/

.login-wrapper {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;

}
.login-panel {
    float: left;
    width: 500px;
    height: auto;
	background: var(--white);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 / 10%);
}
.dark-mode .login-panel {
	background: var(--dark);
}
.login-header {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    margin-bottom: 30px;
	position: relative;
}
.login-header img {
    float: left;
    width: 125px;
}
.login-content {
    float: left;
    width: 100%;
}
.input-field {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.input-field > label {
    float: left;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.input-field input {
    float: left;
    width: 100%;
    height: 45px;
	font-family: "Open Sans";
    font-size: 16px;
	font-weight: 600;
	border: 0;
    border-bottom: 1px solid var(--border);
    border-radius: 0px;
    padding: 0px;
}
.dark-mode .input-field input {
    background: var(--dark);
	color: var(--white);
    border-bottom: 1px solid var(--dark-lt-text);
}
.input-field input::placeholder {
	font-weight: 400;
}
.dark-mode .input-field input::placeholder {
	opacity: .5;
}
.submit-block {
    float: left;
    width: 100%;
}
.direct-login-box {
    float: left;
    width: 100%;
    margin-bottom: 25px;
}
.direct-login-box .general-btn {
    float: left;
    width: 100%;
    height: 50px;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    justify-content: center;
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
    text-transform: uppercase;
    font-weight: 600;
}
.forgot {
    float: left;
    width: 100%;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
    margin-bottom: 20px;
}
.forgot a {
    font-size: 14px;
    font-weight: 600;
    color: var(--default-color)
}

/*------ Login Page End ------*/

/*------ Modal ------*/

.fade {
	opacity: 0;
	transition: opacity .15s linear;
}
.h-modal {
    float: left;
    width: 100%;
    height: 100%;
	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	padding: 15px;
    display: none;
    overflow: hidden;
	background: rgb(0 0 0 / 50%);
    outline: 0;
    z-index: 10;
}
.fade.show {
    opacity: 1;
}
.modal.show .modal-dialog {
    -webkit-transform: translate(0,0);
    transform: translate(0,0);
}
.modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,0%);
    transform: translate(0,0%);
}
.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
	display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	align-items: center;
	-webkit-align-items: center;
	-moz-box-align: center;
	-ms-align-items: center;
    min-height: calc(100% - (.5rem * 2));
}
.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
	-webkit-flex-direction: column;
	-moz-box-direction: column;
	-ms-flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}
.dark-mode .modal-content {
	background: var(--dark);
}
.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
.dark-mode .modal-header {
    border-color: var(--dark-lt-text);
}
.modal-header h5 {
    font-weight: 600;
}
.close-mod {
    float: left;
    width: 15px;
    height: 14px;
	background: url(../images/icons/close-grey.svg) no-repeat;
	background-size: cover;
}
.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
    align-items: center;
	-webkit-align-items: center;
  	-moz-box-align: center;
  	-ms-align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}
.dark-mode .modal-footer {
    border-color: var(--dark-lt-text);
}
.modal-footer .general-btn {
    margin-left: 10px;
}
.modal-body p {
	font-size: 16px;
	line-height: normal;
}

/*------ Modal End ------*/

/* 404 Page */

.notFound {
	width: 100%;
	float: left;
	height: calc(100vh - 70px);
	display: flex;
	justify-content: center;
	align-items: center;
}
.notFound .card {
	float: left;
    width: 100%;
	max-width: 600px;
	padding: 35px 0px;
	text-align: center;
}
.notFound p {
	font-size: 30px;
	font-weight: 600;
	color:  var(--filler-text);
}
.notFound .largeText {
	font-size: 50px;
	font-weight: 600;
	color:  var(--default-color);
	margin-bottom: 5px;
}
.dark-mode .notFound .largeText {
	color: var(--dark);
}
.dark-mode .notFound p {
	color:  var(--dark);
}

/* 404 Page End*/

/* Dark mode end */

.dark-mode .brand-logo img.light {
    display: none;
}
.light-mode .brand-logo img.dark {
    display: none;
}
.dark-mode .brand-logo img.dark {
    display: block;
}
.light-mode .brand-logo img.light {
    display: block;
}
.dark-mode .login-header img.light {
    display: none;
}
.light-mode .login-header img.dark {
    display: none;
}
.dark-mode .login-header img.dark {
    display: block;
}
.light-mode .login-header img.light {
    display: block;
}

/* Dark mode end */

/*------ Media Queries ------*/

@media (min-width: 576px) {
	.container {
		max-width: 100%;
	}
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
		min-height: calc(100% - (1.75rem * 2));
	}
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2));
	}


}

@media (min-width: 768px) {
	.container {
		max-width: 100%;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 100%;
	}
	.modal-lg {
		max-width: 800px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1260px;
	}
}


@media screen and (max-width: 1439px) and (min-width: 1201px) {
}

@media screen and (max-width: 1300px) and (min-width: 1201px) {
}

@media(max-width: 1200px) {

	/*------ Content Area ------*/

	.section-left {
		width: 215px;
	}
	.section-main {
		width: calc(100% - 480px);
	}
	.section-right {
		width: 215px;
	}

	/*------ Content Area End ------*/

}

@media(max-width: 1024px) {

	/*------ Navbar ------*/

	.Navbar {
		position: fixed;
	}
	.brand-logo {
		width: auto;
		position: absolute;
		left: 45px;
		top: calc(50% - 27px);
	}
	.brand-logo img {
		height: auto;
		width: 95px;
	}
	.dark-mode .brand-logo img {
		height: 75px;
		width: auto;
	}
	.hide-lg {
		display: block;
	}
	.hide-sm {
		display: none;
	}
	.signin {
		font-size: 0;
		padding-left: 21px;
		margin-left: 15px;
	}
	.search-block {
		margin-left: 15px;
	}
	.navbar-right {
		width: auto;

		flex-shrink: 0;
	}
	.navbar-menu {
		width: auto;
		flex-shrink: 0;
	}
	.search-wrapper {
		top: 117px;
	}
	.search-input {
		padding-right: 40px;
	}
	.search-input input {
		width: 100%;
	}

	/*------ Navbar End ------*/

	/*------ Content Area ------*/

	.content-area{
		padding: 132px 0 15px 0;
	}
	.section-left {
		width: 100%;
		height: auto;
		z-index: 9;
	}
	.section-main {
		width: 100%;
	}
	.section-right {
		display: none;
	}
	.tab-menu {
		position: fixed;
		left: 0;
		top: 74px;
		background: var(--white);
		border-top: 1px solid var(--border);
		border-bottom: 1px solid var(--border);
		display: flex;
		overflow-x: auto;
		z-index: 9;
	}
	.dark-mode .tab-menu {
		background: var(--dark);
		border-color: var(--dark-lt-text);
	}	
	.tab-menu li {
		width: auto;
		flex-shrink: 0;
	}
	.tab-menu li nav {
		width: auto;
		padding: 0 15px 0 40px;
		line-height: 40px;
	}
	.tab-menu li nav:after {
		width: unset;
		height: 4px;
		top: auto;
		bottom: 0;
	}
	.tab-menu li.active nav:after {
		height: 4px;
		width: 100%;
	}
	.filter-mob-panel {
		float: left;
		width: 100%;
		height: 40px;
		background: var(--white);
		display: flex;
		align-items: center;
		position: fixed;
		left: 0;
		top: 102px;
		padding: 0 0 0 90px;
		border-bottom: 1px solid var(--border);
		display: none;
	}
	.filter-tab {
		float: left;
		width: 100%;
		height: 100%;
		background: var(--white);
		position: fixed;
		left: -100%;
		top: 0;
		transition: .3s ease-out;
		z-index: 10;
	}
	.filter-tab:after {
		content: "";
		width: 441px;
		height: 532px;
		background: url("../images/icons/Bull-shade.png");
		background-size: cover;
		position: absolute;
		right: 0;
		bottom: 0;
		opacity: .5;
	}
	.filter-tab.fly-in {
		left: 0;
	}
	.dark-mode .filter-tab {
		background: var(--dark);
	}
	.filter-panel {
		height: 100%;
		position: relative;
		z-index: 1;
	}
	.filter-header h5 {
		color: var(--default-color);
	}
	.dark-mode .filter-header h5 {
		color: var(--white);
	}
	.filter-panel-body {
		height: calc(100% - 148px);
		overflow-y: auto;
	}
	.each-filter-panel > h5 {
		color: var(--default-color);
	}
	.dark-mode .each-filter-panel > h5 {
		color: var(--white);
	}
	.filter-btn {
		float: left;
		width: auto;
		height: 25px;
		padding: 0 10px;
		border: 1px solid var(--border);
		font-size: 10px;
		font-weight: bold;
		position: absolute;
		left: 15px;
	}
	.filter-bottom .border-btn {
		color: var(--white);
		background: var(--default-color);
		border-color: var(--default-color);
	}
	.filter-tag-wrapper {
		float: left;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		overflow-y: auto;
	}
	.each-filter-tag {
		float: left;
		width: auto;
		height: 25px;
		background: #EFEFEF;
		border-radius: 4px;
		display: flex;
		align-items: center;
		font-size: 11px;
		font-weight: 600;
		padding: 0 25px 0 10px;
		margin-right: 10px;
		flex-shrink: 0;
		position: relative;
	}
	.tag-close {
		float: left;
		width: 10px;
		height: 10px;
		background: url(../images/icons/close-grey.svg) no-repeat;
		background-size: cover;
		position: absolute;
		right: 8px;
		top: 8px;
	}
	.filter-header {
		pointer-events: none;
		padding-right: 35px;
	}
	.filter-header h5:after {
		content:none;
	}
	.mob-close {
		display: block;
	}
	.alt-login-box {
		flex-direction: column;
	}
	.alt-login-box button {
		width: 100%;
		margin: 0;
		margin-bottom: 12px;
		justify-content: center;
	}
	.alt-login-box span {
		width: 100%;
	}
	.grid-image {
		height: auto;
	}
	.grid-description-footer .share-story .share-container {
		right: auto;
	}
	.filter-bottom {
		position: relative;
		left: 0;
		top: 0;
		background: transparent;
	}
	.social-media-wrapper {
		display: none;
	}
	.search-wrapper.fly-in + .content-area .section-left {
		height: auto;
	}
	.dark-mode .filter-panel .filter-bottom .border-btn {
		color: var(--white);
		border: transparent;
	}

	/*------ Content Area End ------*/

}

@media(max-width: 991px) {
}

@media(max-width: 767px) {

	/*------ Navbar ------*/

	.aside-menu {
		width: 100%;
		left: -100%;
		box-shadow: none;
	}
	.navbar-right .general-btn {
		padding: 0 10px;
	}

	/*------ Navbar End ------*/

	/*------ Content Area ------*/

	.each-grid {
		margin-bottom: 20px;
		position: relative;
	}
	.each-grid.no-image {
		padding-top: 20px;
	}
	.grid-description-footer {
		justify-content: space-between;
	}
	.anchor-links {
		display: none;
	}
	.article-image {
		height: 180px;
	}
	.article-content h3 {
		font-size: 18px;
	}
	.article-content ul li {
		font-size: 14px;
	}
	.article-content > p, .article-content p {
		font-size: 14px;
	}
	.article-content blockquote {
		padding: 40px 0px 40px 55px;
	}
	.article-content blockquote:before {
		left: 0;
	}
	.article-content blockquote h5 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.article-content blockquote p {
		font-size: 16px;
	}
	.boiler-banner h5 {
		font-size: 20px;
	}
	.boiler-content > p {
		font-size: 14px;
	}
	.price-tag-box li .price-tag-box-anchor {
		padding: 0 20px;
		height: 100px;
	}
	.price-tag-box li:last-child .price-tag-box-anchor {
		margin-bottom: 0;
	}
	.price-tag-left h5 {
		font-size: 20px;
		line-height: normal;
		margin-top: 12px;
		white-space: nowrap;
	}
	.price-tag-right p {
		font-size: 12px;
	}
	.price-tag-right h5 {
		font-size: 16px;
		line-height: normal;
	}
	.price-tag-left p {
		font-size: 29.1856px;
		line-height: 32px;
	}

	.button-group .each-btn {
		float: left;
		width: 100%;
		margin-bottom: 10px;
	}
	.story-placeholder p {
		font-size: 20px;
		text-align: center;
	}
	.grid-description-content h5 {
		font-size: 18px;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.content-area.sub-page {
		padding-top: 117px;
		padding-bottom: 0;
	}
	.sub-page .container {
		padding: 0;
	}
	.sub-page .boiler-banner {
		float: left;
		height: 100px;
		justify-content: center;
		border-radius: 0;
	}
	.sub-page .boiler-banner:after {
		width: 202px;
		height: 100px;
	}
	.filter-panel-body {
		height: calc(100% - 167px);
	}
	.form-container.contact > p {
		font-size: 13px;
		line-height: normal;
	}
	.boiler-panel {
		border-radius: 0;
	}
	.boiler-nav {
		display: flex;
	}
	.boiler-nav a {
		flex-shrink: 0;
		padding: 0 12px;
	}

	/*------ Content Area End ------*/

	/*------ Login Page ------*/

	.login-wrapper {
		padding: 15px;
		overflow-y: auto;
	}
	.login-panel .alt-login-box {
		flex-direction: column;
	}
	.login-panel .alt-login-box .gl, .alt-login-box .fb {
		margin: 0 0 15px;
		width: 100%;
		text-align: di;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.login-panel .alt-login-box span {
		width: 100%;
	}
	.section-left > div > div {
		-webkit-overflow-scrolling: inherit !important;
	}

	/*------ Login Page End ------*/


}

@media(max-width: 320px) {

	.brand-logo img {
		width: 85px;
	}
}
@media(max-width: 450px) {
	.brand-logo img {
		height: auto;
		width: 95px;
	}
	.price-tag-box li .price-tag-box-anchor:has(.priceTag__remark) {
		position: relative;
		padding-bottom: 50px;
	}
	.priceTag__remark p {
		font-size: 10px;
		line-height: 10px;
	}
}
/*------ Media Queries End ------*/
